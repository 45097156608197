var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"easyPrint",staticClass:"ml-0",staticStyle:{"max-width":"8in","background-color":"transparent"},attrs:{"id":"to_hit_cards_id"}},[(_vm.pages.length > 0)?_c('div',{staticStyle:{"max-width":"8in"}},_vm._l((_vm.pages),function(pg,index1){return _c('div',{key:index1,staticClass:"page ml-0"},[_vm._v(" "+_vm._s(index1)+" "),_c('v-container',_vm._l((_vm.rows),function(row,index2){return _c('v-row',{key:index2,staticClass:"justify-space-around",staticStyle:{"width":"100%","height":"3.1in"},attrs:{"align-items:":"","start":""}},_vm._l((_vm.cols),function(col,index3){return _c('v-col',{key:index3,staticClass:"dc_container"},[_c('div',{staticClass:"dicecard d-flex flex-column justify-center align-center mt2"},[_c('small',[_vm._v("Beta FX©")]),_c('small',{staticClass:"mb-4"},[_vm._v("Every Di Dicecard")]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"800"}},[_c('span',[_vm._v("3d6:")]),_c('span',{class:[
                    _vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].a3d6 ==
                      18 ||
                    _vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].a3d6 ==
                      3
                      ? _vm.rr.toString()
                      : '',
                  ]},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].a3d6)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("d100:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].pnt)+" ")])]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"800"}},[_c('span',[_vm._v("d4:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].d4)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("d6:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].d6)+" ")])]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"800"}},[_c('span',[_vm._v("d8:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].d8)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("d12:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].d12)+" ")])]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"800"}},[_c('span',[_vm._v("2d4:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].a2d4)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("3d4:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].a3d4)+" ")])]),_c('p',{staticStyle:{"font-size":"18px","font-weight":"800"}},[_c('span',{staticClass:"ml-2"},[_vm._v("d20:")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.all_everydi[index1 * 9 + index2 * 3 + index3 + 1].d20)+" ")])]),_c('v-spacer'),_c('small',{staticClass:"mr-1",staticStyle:{"width":"100%","text-align":"right"}},[_vm._v(_vm._s(index1 * 9 + index2 * 3 + index3 + 1))])],1)])}),1)}),1)],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }